<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12 pb-4>
        <span style="font-family: poppinssemibold">Fund Details</span>
      </v-flex>
      <v-flex xs12 md3 text-left>
        <v-autocomplete
          shaped
          dense
          label="Status"
          :items="filters"
          v-model="filter"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 md3 text-left pl-1>
        <v-autocomplete
          shaped
          dense
          label="Employee"
          item-color="green"
          item-text="name"
          item-value="_id"
          :items="Employees"
          v-model="employee"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 md3 text-left pl-1>
        <v-autocomplete
          shaped
          dense
          label="Project"
          item-color="green"
          item-text="projectName"
          item-value="_id"
          :items="projects"
          v-model="project"
        >
        </v-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="fundDetails"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:item.create_date="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
            <!-- <span>{{item.date.splice(0,10) }}</span> -->
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn icon @click="rowClicked(item)">
              <v-icon small color="primary"> mdi-eye</v-icon>
            </v-btn>
          </template>
           <!-- <template v-slot:item="{ item }">
            <v-btn icon @click="rowClicked(item)">
              <v-icon small color="primary"> mdi-eye</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog width="600px" v-model="myDialog">
      <v-card width="600px" ma-4>
        <v-layout wrap text-left pa-4 v-if="this.curData">
          <v-flex
            xs12
            class="white--text"
            style="background-color: #005f32; border-radius: 24px"
            text-center
            mb-4
          >
            <h4>{{ this.curData.targetid.projectid.projectName }}</h4>
          </v-flex>
          <v-flex xs6 class="RB"> Date : </v-flex>
          <v-flex xs6 class="RM">
            {{ new Date(this.curData.create_date).toLocaleString() }}</v-flex
          >

          <v-flex xs6 class="RB">Project Amount </v-flex>
          <v-flex xs6 class="RM"
            >{{ this.curData.targetid.totalTarget }} (INR)
          </v-flex>
          <v-flex xs6 class="RB"> Employee: </v-flex>
          <v-flex xs6 class="RM">
            <router-link :to="'/userView?id=' + curData.empId._id">
              <span style="color: blue"> {{ this.curData.empId.name }} </span>
            </router-link>
          </v-flex>

          <v-flex xs12 py-1>
            <v-divider></v-divider>
          </v-flex>
          <template v-if="curData.amountAchieved != 0">
            <v-flex xs6 class="RB" pt-1> Alredy received</v-flex>
            <v-flex xs12 class="RM">
              <v-text-field dense v-model="NewAmt"> </v-text-field>
            </v-flex>
          </template>
          <template v-if="curData.committed != 0">
            <v-flex xs6 class="RB"> Committed and Confirmed </v-flex>
            <v-flex xs12 class="RM">
              <v-text-field dense v-model="NewAmt"> </v-text-field>
            </v-flex>
          </template>
          <template v-if="curData.notconfirmed != 0">
            <v-flex xs6 class="RB"> Committed but not Confirmed</v-flex>
            <v-flex xs12 class="RM">
              <v-text-field dense v-model="NewAmt"> </v-text-field>
            </v-flex>
          </template>
          <template v-if="curData.toberaised != 0">
            <v-flex xs6 class="RB">To be raised</v-flex>
            <v-flex xs12 class="RM">
              <v-text-field dense v-model="NewAmt"> </v-text-field>
            </v-flex>
          </template>
          <!-- <v-layout>
            <v-flex xs1 text-right>
              <v-btn outlined color="green" @click="editData(NewAmt, curData)">
                edit
              </v-btn>
            </v-flex>
          </v-layout> -->

          <!-- <v-flex xs12>
                 <v-text-field label="change Amount" dense outlined v-model="NewAmt">
              </v-text-field>

            </v-flex> -->
          <v-flex
            xs12
            text-right
            class="RB"
            v-if="this.curData.projectStatus == 'Pending'"
          >
            <v-btn x-small text @click="ApproveConfirm = true" color="primary">
              Approve</v-btn
            >
            <v-btn x-small text @click="RejectConfirm = true" color="error">
              Reject</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog width="500px" v-model="ApproveConfirm">
      <v-card width="500px" ma-3>
        <v-layout wrap pa-2>
          <v-flex xs12>
            <span> Are you Sure you want to Approve this Entry</span>
          </v-flex>
          <v-flex xs12 text-right class="RB">
            <v-btn x-small text color="primary" @click="approve(curData._id)">
              ok</v-btn
            >
            <v-btn
              x-small
              ml-3
              text
              color="error"
              @click="ApproveConfirm = false"
            >
              cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="RejectConfirm" width="500px">
      <v-card width="500px" ma-3>
        <v-layout wrap>
          <v-flex xs12 pb-3>
            <span class="RM"> Are you Sure you want to reject this Entry</span>
          </v-flex>
          <v-flex xs12 text-right class="RB">
            <v-btn
              x-small
              text
              color="primary"
              ml-3
              @click="reject(curData._id)"
            >
              ok</v-btn
            >
            <v-btn x-small text color="error" @click="RejectConfirm = false">
              Cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      NewAmt: 0,
      projects: [],
      project: null,
      Employees: [],
      employee: null,
      filter: "Pending",
      filters: ["Pending", "Rejected", "Approved"],
      showsnackbar: false,
      ApproveConfirm: false,
      RejectConfirm: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      fundDetails: [],
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "create_date",
        },
        { text: "Project", value: "targetid.projectid.projectName" },
        { text: "Employee", value: "empId.name" },
        { text: "Already received", value: "amountAchieved" },
        { text: "Committed and Confirmed", value: "committed" },
        { text: "Committed but not Confirmed", value: "notconfirmed" },
        { text: "Note", value: "description" },
        { text: "To be raised", value: "toberaised" },
        { text: "Action", value: "_id" },
      ],
      myDialog: false,
      curData: null,
      myDialog2: false,
      curData2: null,
    };
  },
  beforeMount() {
    this.getData();
    this.getEmployees();
    this.getProject();
  },
  watch: {
    filter() {
      this.getData();
    },

    employee() {
      this.getData();
    },

    project() {
      this.getData();
    },
  },

  methods: {
    getProject() {
      this.appLoading = true;
      axios({
        url: "/projects/getalllist/fundraise",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projects = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmployees() {
      axios({
        url: "superadmin/view/employeelist",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.Employees = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
editData(amt, id) {
      this.appLoading = true;
      axios({
        url: "/project/fund/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data:{
          amount : amt,
          id:id._id
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.projects = response.data.data;
      this.showSnackBar= true;
           this.myDialog = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.showSnackBar= true;
          this.ServerError = true;
          console.log(err);
        });
    },
    rowClicked(item) {
      this.myDialog = true;
      this.curData = item;

      if (this.curData.amountAchieved != 0) {
        this.NewAmt = this.curData.amountAchieved;
      }
      if (this.curData.committed != 0) {
        this.NewAmt = this.curData.committed;
      }
      if (this.curData.notconfirmed != 0) {
        this.NewAmt = this.curData.notconfirmed;
      }
      if (this.curData.toberaised != 0) {
        this.NewAmt = this.curData.toberaised;
      }
      // console.log(" this.curData", this.curData);
    },

    rowClicked2(item) {
      this.myDialog2 = true;
      this.curData2 = item;
      console.log(" this.curData", this.curData2);
    },

    getData() {
      if (this.filter == "All") {
        this.filter = "";
      }

      this.appLoading = true;
      axios({
        method: "GET",
        url: "/projectraised/fund/pending/getallist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          status: this.filter,
          empId: this.employee,
          projectId: this.project,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.fundDetails = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    approve(val) {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/project/fund/approval",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: val,
          amount: this.NewAmt,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.myDialog = false;
            this.ApproveConfirm = false;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    reject(val) {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/project/fund/rejection",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: val,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.myDialog = false;
            this.RejectConfirm = false;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.v-select {
  font-family: poppinsbold !important;
}
.v-autocomplete {
  font-family: poppinsbold !important;
}
</style>